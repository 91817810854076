/* .request_banner_main{
  
    background-color: radial-gradient(12.32% 62.32% at 48.69% 40.58%, #3EC8F7 7.38%, rgba(255, 255, 255, 0.00) 100%);
    width:100%;
    height:500px;
    
} */
.enhance_div {

    /* background-size: 350px ; */
    background-repeat: no-repeat;
    background-position: center;
    background-image: url('../assets/images/back-1.png');
    /* width:100%; */
    height: 100%;
    padding-top: 5rem;
    padding-bottom: 4rem;
    padding-left: 3rem;
    padding-right: 3rem;
    position: relative;

}

.enhance_div h1 {
    color: #ED553D;
    font-family: 'Work Sans';
    font-size: 60px;
    font-style: normal;
    font-weight: 700;
    line-height: 46.5px;
    /* 77.5% */
    align-items: start;
    position: absolute;
    top: 10%;
    left: 30%;
}

.enhance_div P {
    color: #000;
    text-align: center;
    font-family: 'Work Sans';
    font-size: 1.3rem;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
    /* 128% */
    padding-left: 3rem;
    padding-right: 3rem;

}

.death_div {

    /* background-size: 350px ; */
    background-repeat: no-repeat;
    background-position: center;
    background-image: url('../assets/images/back-2.png');
    /* width:100%; */
    height: 100%;
    padding-top: 5rem;
    padding-bottom: 4rem;
    padding-left: 3rem;
    padding-right: 3rem;
    position: relative;

}

.inc_div {

    /* background-size: 350px ; */
    background-repeat: no-repeat;
    background-position: center;
    background-image: url('../assets/images/back-3.png');
    /* width:100%; */
    height: 100%;
    padding-top: 5rem;
    padding-bottom: 4rem;
    padding-left: 3rem;
    padding-right: 3rem;
    position: relative;

}

.inc_div h1 {
    color: #ED553D;
    font-family: 'Work Sans';
    font-size: 60px;
    font-style: normal;
    font-weight: 700;
    line-height: 46.5px;
    /* 77.5% */
    align-items: start;
    position: absolute;
    top: 10%;
    left: 30%;
}

.death_div P {
    color: #000;
    text-align: center;
    font-family: 'Work Sans';
    font-size: 1.3rem;
    font-style: normal;
    font-weight: 500;
    margin: 0 !important;
 
    /* 128% */

}

.death_div h1 {
    color: #ED553D;
    font-family: 'Work Sans';
    font-size: 60px;
    font-style: normal;
    font-weight: 700;
    line-height: 46.5px;
    /* 77.5% */
    align-items: start;
    position: absolute;
    top: 10%;
    left: 30%;
}

.inc_div P {
    color: #000;
    text-align: center;
    font-family: 'Work Sans';
    font-size: 1.3rem;
    font-style: normal;
    font-weight: 500;
    margin: 0 !important;

    /* 128% */
   
}
.our_imp a{
    text-decoration: none;

}

.our_impact_button{
    border-radius: 5px;
    background: #ED553D; 
    display: flex;
padding: 15px 25px;
align-items: center;
gap: 15px;
color: #FFF;
/* btn-text */
font-family: 'Montserrat';
font-size: 14px;
font-style: normal;
font-weight: 700;
line-height: 22px; /* 157.143% */
letter-spacing: 0.2px;
border: none;
margin: auto;

}