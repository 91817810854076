.our_impact_main_head {
    text-align: center;
    justify-content: center;
}

.our_impact_main_head h2 {
    color: #000;
    font-family: 'Work Sans';
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 29.5px;
    /* 65.556% */
}
.plus_div {
    background-image:
    url('../assets/images/busines_plus.png');
    background-position: top right;
    background-repeat: no-repeat;
   background-size: contain;
background-size: 200px;
max-width: 100%;
  /* height:250px; */
}
.row_patient {
    padding: 30px;
    display: flex;
    flex-wrap: wrap;
}

.patient_colum {
    flex: 1;
}

.card-main-p {
    border-radius: 11.695px;
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.24) 0%, rgba(255, 255, 255, 0.06) 100%);
    box-shadow: 0px 0px 11.695446968078613px -5.847723484039307px rgba(0, 0, 0, 0.50);
    backdrop-filter: blur(11.695446968078613px);
}

.patent-div-main {
    display: flex;
    flex-direction: column;

}

.patent-div-sub {
    display: flex;
    flex-direction: row;
}

.patient-text {
    max-width: 260px;
    max-height: 229px;
}

.patient-text h4 {
    color: #0C173A;
    font-family: 'Work Sans';
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 46.5px;
}

.patient-text p {
    color: #7B99AA;
    font-family: 'Work Sans';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 25px;
    /* 156.25% */
    letter-spacing: 0.2px;
}

.patient_card {
    display: flex;
    flex-direction: row;
}

.patient_card_sub {
    border-radius: 11.695px;
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.24) 0%, rgba(255, 255, 255, 0.06) 100%);
    box-shadow: 0px 0px 11.695446968078613px -5.847723484039307px rgba(0, 0, 0, 0.50);
    backdrop-filter: blur(11.695446968078613px);
    width: 267px;
    height: 229px;
    flex-shrink: 0;
    padding: 30px 20px 30px 20px;
    margin: 1rem 1rem;
    align-items: center;
    justify-content: baseline;

}

.patient_img img {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 0 auto;
}

.patient_card_sub_text {

    width: 267px;
    height: 229px;
    flex-shrink: 0;
    padding: 1rem;
    margin: 1rem 1rem;
    align-items: center;
    justify-content: baseline;
}

.patient_card_sub_text h3 {
    color: #0C173A;
    font-family: 'Work Sans';
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 46.5px;
    /* 155% */

}

.patient_card_sub_text p {
    color: #7B99AA;
    font-family: 'Work Sans';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 25px;
    /* 156.25% */
    letter-spacing: 0.2px;
    padding-bottom: 5rem !important;
}

.patient_card_sub span {
    color: #545454;
    font-family: 'Work Sans';
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 31px;
    /* 155% */
    letter-spacing: 0.2px;
    padding-left: 5px;
}

.patient_card_sub h2 {
    color: #ED553D;
    font-family: 'Work Sans';
    font-size: 56px;
    font-style: normal;
    font-weight: 700;
    line-height: 31px;
    /* 55.357% */
    letter-spacing: 0.2px;
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.be-prevent-text {
    color: #545454 !important;
    font-family: 'Work Sans' !important;
    font-size: 20px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 31px !important;
    /* 155% */
    letter-spacing: 0.2px !important;
    padding-left: 5px !important;
}

.busines_gradient {
    width: 100%;
    height: auto;
    background-image: linear-gradient(331deg, #DEF0FC 0%, #65BFD7 100%);
}

.background-image-div {

    height: auto;
    background-image: url("../assets/impact/10.png");
    background-size: contain;

    background-repeat: no-repeat;

}


.busines_image {
    width: 100%;

}

.busines_image img {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 0 auto;
}

.busines_text h1 {
    color: #363636;
    font-family: 'Poppins', sans-serif;
    font-size: 60px;
    font-style: normal;
    font-weight: 700;
    line-height: 73.5px;
    /* 133.636% */
}

.busines_text {
    padding: 90px 0px 50px 0px;
}

@media screen and (max-width: 768px) {
    .background-image-div {
        background-size: cover; 
        background-position: top;
    }

    .patient_colum {
        flex: 1 100%;
        padding: 1rem;
    }

    .patient_card_sub {
        width: 100%;
        /* Expand card to full width */
        margin: 1rem 0;
        /* Adjust margin for spacing */
    }

    .busines_text h1 {
        line-height: 100%;
        font-size: 30px;
text-align: center;
    }

    .busines_text {
        padding: 20px 0px 50px 0px;
    }
}

@media only screen and (min-width: 769px) and (max-width: 991px) {
    .patient_card {
        flex-wrap: wrap;
    }

    .patient_card_sub {
        /* width: 48%;  */
        margin: 1rem 1%;
    }

    .busines_text h1 {
        line-height: 100%;
        font-size: 40px;
    }
}