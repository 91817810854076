.register{
align-items: center;
justify-content: center;
text-align: center;
    border-radius: 5px;

    background-color: white;
  }
  
  /* .register span{
  
    text-align: center !important;
    align-items: center !important;
    justify-content: center;
    margin: auto;
    color: #0C173A;
    font-family: 'Work Sans';
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
   
  }
   */
  #form{
    max-width: 320px;
    /* width: 100vw; */
    margin: 2em auto;
   
  }
  .plus_image_login{
    background-image:
    url('../assets/images/plusimg2.png');
    background-position: top left;
    background-repeat: no-repeat;
   /* background-size: contain; */
background-size: 200px;
  width:100%;
  }
  .plus_image_login_down{
    background-image:
    url('../assets/images/plusimg2.png');
    background-position: bottom right;
    background-repeat: no-repeat;
   /* background-size: contain; */
background-size: 200px;
  width:100%;
  height: auto;
  }
  .form-white{
    background-color: white;
    padding-bottom: 1rem;
   
  }
  .sign-up-text h2{
    color: #0C173A;
    font-family: 'Work Sans';
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 46.5px;
}
  
  
  #form >  input, .btn{
    border: 1px solid #D9D9D9;
    padding: .9em ;
    max-width: 300px;
    display: block;
    border-radius: 4px;
  }
  
  #form > input:focus{
    outline: none;
  }
  
  #form > .btn{
    background-color: #ED553D;;
    
    color: #FFF;
    text-align: center;
    font-family: 'Work Sans';
    font-size: 16px;
    font-style: normal;
  
    line-height: 24px;
    cursor: pointer;
  }
  
  .flex{
    display: flex;
    gap: 1em;
  }
  
  .flex-col{
    flex-direction: column;
  }
  
.form-bg{
    background-color:#CEE9F7; 
    padding: 2rem;
}
  
  @media screen and (min-width: 860px)  {
   
  }
  .login_right_text h1 {
    color: #160637;
    font-family: 'Work Sans';
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px; 
    width:80%;
    text-align: left;
  }
  .boreder_down{
    display: flex;
  }
  .boreder_down p{
    border: 1px solid  #D6D6D6;;
    width:35%;
    height:1px;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .boreder_down span{
    color: #8989A2;
    font-family: 'Work Sans';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    padding-left: 3%;
    padding-right: 3%;
    margin-top: 3px;
  }
  .login_images_div{
    display: flex;
    padding: 1rem;
    flex-direction: row;
    justify-content: space-between;

  }
  .login_images_div img{
    max-width: 100%;
    display: block;

   
    padding: 1rem;

  }
  @media screen and (min-width: 300px) and (max-width: 768px) {
    .plus_image_login {
background-image: none;
    
  }
    .login_right_text h1 {
      width: 100%;
      text-align: center;
  }
    .login_images_div img{
   
      padding: 1rem;
      justify-content: space-around;
      margin: 1rem;
  
    }
    #form{
      max-width: 300px;
      /* width: 0vw; */
      margin: 1em auto;
     
    }
    .form-bg{
      background-color:#CEE9F7; 
      padding: 1rem;
  }
  .form-white{
    background-color: white;
    /* padding-bottom: 1rem; */
    padding: 1rem;
  }
  .boreder_down span{
    font-size: 12px;
    margin-top: 7px;  
  } 
}
  .sign-up-text{
    padding: 1rem;
    color: #160637;
text-align: center;
font-family: 'Work Sans';
font-size: 24px;
font-style: normal;
font-weight: 700;
line-height: 32px; /* 133.333% */
  }
 
  
  /* Your other styles...
  
/*   
  section {
    padding: 2rem;
  }
  
 
  .login_right_text h1 {
    font-size: 28px;
    line-height: 1.4;
    width: 100%;
  }
  
 
  .boreder_down p {
    width: 25%;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  
 
  .login_images_div {
    display: flex;
    flex-wrap: wrap; 
    justify-content: center; 
    padding: 1rem;
  }
  
  .login_images_div img {
    padding: 0.5rem;
    max-width: 100%; 
  }
  
 
  .form-bg {
    background-color: #cee9f7;
    padding: 2rem;
    max-width: 400px;
    margin: 0 auto;
  }
  
  #form {
    max-width: 320px;
    width: 100%;
    margin: 2em auto;
  }
  
  
  .sign-up-text {
    text-align: center;
  }
  
  
  .sign-up-text {
    font-size: 24px;
  }
  
 
  #form > .btn {
    display: block;
    width: 100%;
    text-align: center;
  }
  
  
  #form > .btn {
    font-size: 18px;
  }
  
  
  #form > input,
  .btn {
    margin-bottom: 1rem;
  }
  
 
  @media screen and (min-width: 576px) {
    .login_right_text h1 {
      font-size: 32px;
    }
    
   
    .boreder_down p {
      width: 20%;
    }
  
   
    #form > .btn {
      font-size: 16px;
    }
  } */