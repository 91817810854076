.cardo{
    margin-left: 10%;
    width: 90%;
}
.cardo-1{
    margin-left: 10%;
    width: 90%;
}
.pa-card{
    padding: 6%;
}
.pa-card h3{
    color: #0C173A;
    font-family: 'Work Sans';
    font-size: 35px;
    font-style: normal;
    font-weight: 700;
    line-height: 46.5px; /* 155% */
}
.pa-card p{
    color: #7B99AA;
    font-family: 'Work Sans';
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 25px; /* 156.25% */
    letter-spacing: 0.2px;
    width: 90%;
}

.pat-card{  
border-radius: 11.695px;
background: linear-gradient(135deg, rgba(255, 255, 255, 0.24) 0%, rgba(255, 255, 255, 0.06) 100%);
box-shadow: 0px 0px 11.695446968078613px -5.847723484039307px rgba(0, 0, 0, 0.50);
backdrop-filter: blur(11.695446968078613px);
width: 90%;
padding: 15%;
}
.pat-card h3{
    color: #ED553D;
    font-family: 'Work Sans';
    font-size: 56px;
    font-style: normal;
    font-weight: 700;
    line-height: 31px; /* 55.357% */
    letter-spacing: 0.2px;
}
.pat-card p{
    color: #545454;
    font-family: 'Work Sans';
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 31px; /* 155% */
    letter-spacing: 0.2px;
    margin-top: 15%;
}



.patient_img img{
    max-width: 100%;
    height: auto;
    display: block; 
    margin: 0 auto; 
}

@media only screen and (min-width: 320px) and (max-width: 768px) {
    .pa-card h3 {  
        font-size: 20px; 
        font-weight: 700;
        line-height: 46.5px; /* 155% */
    }
    .pa-card p{  
        font-size: 16px; 
        font-weight: 500;
        line-height: 25px; /* 156.25% */ 
    }
    .cardo{
        width: 90%;
        margin: auto;
    }

    .pat-card{
        width: 100%;
        height: 180px;
    }
    .pat-card-1{
        width: 100%;
        height: 180px;
    }
    .pat-card h3 { 
        font-size: 40px; 
        font-weight: 700;
        line-height: 21.795px; /* 54.487% */
        letter-spacing: 0.141px;
    }
    .pat-card p{ 
        font-size: 14px;  
        font-weight: 500;
        line-height: 21.795px; /* 155.677% */
        letter-spacing: 0.141px;
    }
    .patient_img{
        margin-top: 8%;
    }
    .cardo-1{ 
        width: 90%;
        margin: auto;
        margin-top: 10%;
    }
}
@media only screen and (min-width: 430px) and (max-width: 768px){
.pa-card p {
    font-size: 14px;
    font-weight: 500;
    line-height: 19px !important;
}

.pa-card {
    padding: 1%;
}
}

@media only screen and (min-width: 320px) and (max-width: 430px){
.pa-card p {
    font-size: 12px;
    font-weight: 500;
    line-height: 15px !important;
}
.pa-card {
    padding: 1%;
}
.pa-card h3 {
    font-size: 18px;
    font-weight: 700;
    line-height: 20px;
}
}
@media only screen and (min-width: 769px) and (max-width: 991px){
    .pa-card h3 {
       
        font-family: 'Work Sans';
        font-size: 23px !important;
        font-style: normal;
        font-weight: 700;
         line-height: 26.5px !important; 
    }
    .pa-card p {
      
     
        font-size: 13px !important;
        font-style: normal;
        font-weight: 700;
         line-height: 26.5px !important; 
    }
    .pa-card {
        padding: 1% !important;
    }
    .pat-card h3 {
        color: #ED553D;
        font-family: 'Work Sans';
        font-size: 36px;
        font-style: normal;
        font-weight: 700;
        line-height: 31px;
        letter-spacing: 0.2px;
    }
    .pat-card {
       
        width: 100%;
   
    }

}