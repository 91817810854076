.about_banner_main {
    background-image: url('../assets/about/about_banner.png');
    background-position: center;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;

}

.about_banner_main_text {
    align-items: center;
    text-align: center;
    margin: auto;
}

.about_banner_main_text p {
    color: #0C173A;
    ;
    text-align: center;
    font-family: 'Work Sans';
    font-size: 25px;
    font-style: normal;
    font-weight: 500;
    line-height: 36px;
    /* 120% */
    letter-spacing: 0.2px;
    align-items: center;
    text-align: center;
    margin: auto;
    width: 65%;
    padding-bottom: 3rem;
}

.about_banner_sub {
    background-image:
        url('../assets/about/right.png');
    background-position: top right;
    background-repeat: no-repeat;
    background-size: contain;
    background-size: 200px;
    width: 100%;
    /* height:250px; */
}

.about_banner_sub_left {
    background-image:
        url('../assets/about/left.png');
    background-position: top left;
    background-repeat: no-repeat;
    background-size: contain;
    background-size: 200px;
    width: 100%;
    /* height:250px; */
}

.about_banner_main_text button {
    border-radius: 45px;
    border: 2px solid #ED553D;
    color: #ED553D;
    font-family: 'Montserrat';
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0.2px;
    /* padding: 1rem 2rem 1rem 2rem; */
    width: 140px;
    height: 50px;
    background: none;

}

.bg_card_about {
    border-radius: 20px;
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.24) 0%, rgba(255, 255, 255, 0.06) 100%);
    box-shadow: 0px 0px 9.045938491821289px -4.5229692459106445px rgba(0, 0, 0, 0.50);
    backdrop-filter: blur(9.045938491821289px);
    padding: 1rem;
    height: 200px;
}

.bg_card_about h4 {
    color: #7B99AA;
    font-family: 'Work Sans';
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 31px;
    /* 172.222% */
    letter-spacing: 0.2px;
}

.bg_card_about span {
    display: flex;
    color: #ED553D;
    font-family: 'Work Sans';
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 31px;
    /* 172.222% */
    letter-spacing: 0.2px;
    align-items: right;
    text-align: right;
    justify-content: right;
}

@media screen and (min-width: 320px) and (max-width: 768px) {

    .about_banner_main_text p {

        font-size: 18px;

    }

    .bg_card_about {
        border-radius: 20px;
        background: linear-gradient(135deg, rgba(255, 255, 255, 0.24) 0%, rgba(255, 255, 255, 0.06) 100%);
        box-shadow: 0px 0px 9.045938491821289px -4.5229692459106445px rgba(0, 0, 0, 0.50);
        -webkit-backdrop-filter: blur(9.045938491821289px);
        backdrop-filter: blur(9.045938491821289px);
        padding: 0.6rem;
        height: 200px;
        margin-bottom: 2rem;
    }
    .about_banner_main_text p {
      
        width: 85%;
           padding-bottom: 0rem;
    }
}