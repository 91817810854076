.contact_main_bg {
    border-radius: 10px;
    background: #FFF;
    box-shadow: 0px 0px 60px 30px rgba(0, 0, 0, 0.03);
    height: auto;
}


.contact_left_bg {
    background-color: #325EE9;
    background-image: url('../assets/about/round.png');
    background-repeat: no-repeat;
    background-position: right bottom;
}
.success-message{
    color:darkgreen;
}

.contact_us_text_head h1 {
    color: #325EE9;
    font-family: 'Poppins';
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: center;
}

.contact_information_div {
    padding: 3rem;
}

.contact_information_div h1 {
    color: #FFF;
    font-family: 'Poppins';
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.contact_information_div h3 {
    color: #C9C9C9;
    font-family: 'Poppins';
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.contact_us_text_head p {
    color: #717171;
    text-align: center;
    font-family: 'Poppins';
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.phone_display_div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
}

.phone_display_div img {
    display: flex;
    padding-right: 1rem;

}

.phone_display_div p {
    color: #FFF;
    font-family: 'Poppins';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-left: 1rem;
    width: 80%;
}

.form-wrapper {
    display: flex;
    max-width: 100%;
    padding-top: 1rem;
    align-items: center;
    justify-content: center;
}

.input-group {
    position: relative;
    margin: 2rem 1rem;
    border-bottom: solid #8D8D8D 1px;
}

.message_div {
    margin: 2rem 3rem 1rem 1rem !important;
}

.input-group label {
    position: absolute;
    top: 5%;
    left: 5px;
    transform: translateY(-50%);
    pointer-events: none;
    transition: .5s;
    color: #8D8D8D;
    font-family: 'Work Sans';
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
}

.input-group .message_label{
    position: absolute;
    top: 5%;
    left: 5px;
    transform: translateY(-50%);
    pointer-events: none;
    transition: .5s;
    color: #8D8D8D;
    font-family: 'Poppins';
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
}
.input-group input {
    width: 300px;
    height: 45px;
    font-size: 14px;
    color: #8989A2;
    padding: 0 5px;
    background: transparent;
    border: none;
    outline: none;
}
.input-group input::placeholder {
padding-top: 1rem;
margin-top: 1rem;
color: #8D8D8D;
    font-family: 'Poppins';
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
}

.sign-in {
    font-size: 14px;
    text-align: center;
    margin: 15px 0px;
}

.input-group input:focus~label,
.input-group input:valid~label {
    top: 5px;
}

.input-group_div {
    display: flex;
    display: row;
    justify-content: space-between;
}
.request-button{
    background-color: #ED553D;
    color: #FFF;
    text-align: center;
    font-family: 'Work Sans';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
    outline: none;
    border: none;
    padding: 1rem 3rem;
    max-width: 300px;
}
.request-button-div{
    align-items: center;
    text-align: center;
    justify-content: center;
}
.request_form_bg{
    border-radius: 10px;
    background: #FFF;
    box-shadow: 10px 10px 25px 10px rgba(56, 86, 223, 0.05);
    max-width: 100%;
}
.request_banner_heading_main{
     text-align: center !important; 
     justify-content: center !important; 
     align-items: center !important;
     margin: auto !important;
}
.request_banner_heading_main h1{
    color: #0C173A;
    font-family: 'Work Sans';
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 55.959px; /* 139.896% */
    text-transform: capitalize;
    text-align: left !important;
}
.request_heading-form{
    color: #ED553D;
    font-family: 'Work Sans';
    font-size: 21px;
    font-style: normal;
    font-weight: 700;
    line-height: 25px; /* 119.048% */
    letter-spacing: 0.2px;
    padding-left: 1rem;
}

.send-button {
    display: inline-flex;
    padding: 12px 23px 11px 23px;
    justify-content: flex-end;
    align-items: flex-end;
    border-radius: 5px;
    background: #325EE9;
    box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.12);
    color: #FFF;
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    outline: none;
    border: none;
}

.send-button-div {
    align-items: end;
    justify-content: end;
    text-align: end;
}
@media screen and (min-width:320px) and (max-width:760px) {
    .form-wrapper{
        margin-left: 0%;
    }
    .input-group_div{
        display: block; 
    } 
    .input-group input{
        width: 80%;
        margin: auto;
    }
    .input-group .message_label{
        margin-top: -2% !important;
    }
   
}
@media screen and (min-width:320px) and (max-width:760px) {
    .send-button-div {
        align-items: end;
        justify-content: end;
        text-align: end;
        padding-bottom: 1rem;
    }
    .request_banner_heading_main h1 {
        color: #0C173A;
        font-family: 'Work Sans';
        font-size: 30px;
        font-style: normal;
        font-weight: 700;
        line-height: 55.959px;
        text-transform: capitalize;
        text-align: left !important;
    }
    .demo_mobile{
        flex-direction: column;
    }
}
