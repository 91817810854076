.footer-bg {
    /* fill: linear-gradient(360deg, rgba(108, 214, 249, 0.10) 23.59%, rgba(251, 217, 2, 0.10) 40.76%, rgba(106, 213, 106, 0.10) 55.89%, rgba(33, 95, 242, 0.15) 69.99%); */
    height: auto;
    display: flex;
    align-items: center;
    padding: 40px   0px 0px;
    /* background-image: linear-gradient(360deg, rgba(108, 214, 249, 0.10) 23.59%, rgba(251, 217, 2, 0.10) 40.76%, rgba(106, 213, 106, 0.10) 55.89%, rgba(33, 95, 242, 0.15) 69.99%); */
    background-image:linear-gradient(179deg, #2F2C45 0%, #3C3083 100%);
  }
  /* .copy-empty{
      height: 200px;
  } */
  .kode_footer_bg{
    background-size: 750px ;
    background-repeat: no-repeat;
    background-position: right 100px bottom;
    background-image: url('../assets/images/kode-footer.png');
    width:100%;
    height: auto;
  
  }  
  
  .sports-verse-heading{
    font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 32.4706px;
  line-height: 49px;
  /* identical to box height */
  
  letter-spacing: -0.634116px;
  
  color: #FFFFFF;
  
  opacity: 0.65;
  
  }
  .copy-right-text{
    color: #FFFFFF;
    font-family: 'Poppins';
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 166.667% */
    width:80%;
  }
  
  .footer-links{
    padding:0px;
    margin:0px;
  }
  .footer-links a{
    text-decoration: none;
  }
  .footer-links li, .footer-links li a {
  text-decoration: none;
  list-style: none;
  color:#FFFFFF;
font-family: 'Poppins';
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
  padding:0px;
  width:166px;
  
  }
  .footer-links li:hover{
    /* color: #EA9528;
    cursor: pointer; */
    
  }
  
  .footer-social-header{
    display: flex;
    gap:30px;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    margin-top:36px;
  }
  .sports-logo-column{
    margin-top:-12px;
  }
  .footer-social-header-1{
    display: flex;
    gap:30px;
    flex-direction: row;
    align-items: center;
    /* justify-content: center; */
    margin-top:36px;
    text-align: center;
  
  }
  .div-logo-footer{
    align-items: center;
    display: flex;
  }
  
  .footer-social-icons a img{
    width: 33px;
  }
  @media (min-width: 1024px) {
    .footer-links li {
      padding-bottom: 30px !important;
    }
  }
  
  @media screen and (min-width: 320px) and (max-width:990px){
    .copy-right-text{
      font-size: 14px;
      /* text-align: center; */
    }
    .footer-links li{
      font-size: 12px;
      width:99px;
      margin-left:-6px;
    }
    .footer-links li a{
      font-size: 12px;
  
    }
    .div-logo-footer{
      align-items: center;
      display: flex;
      text-align: center;
      /* justify-content: center; */
    }
  
    .sports-verse-heading{
    font-size: 21.576px;
  
    }
    /* .sports-logo{
      width:35px;
      height: 45px;
    } */
    .footer-r{
      width: 100%;
      margin: auto;
    }
    .footer-social-icons-img{
      width:28px !important;
     
    }
    .kode_footer_bg{
      /* background-size: 400px ; */
    
      background-position: center bottom;
      height:auto;
      max-width: 100%;
    } 
  }
   
  
  @media(max-width:992px){
  
    .footer-bg {
      height: auto;
    }
  
    .footer-meta-social{
      display: flex;
      align-items: center;
      /* justify-content: center; */
      gap:45px;
      margin-bottom:12px;
      /* flex-direction: column; */
      
    }
    .footer-meta-icon{
      /* border:2px solid red; */
      height: 15px;
    }
  
    .footer-social-header{
  
    margin-top: 0px;
  }
  .footer-social-icons a img{
    width:20px;
   
  }
  .footer-social-header-1{
  
    margin-top: 0px;
  }
  
  
  
  }
  
   
  @media(max-width:400px){
    .footer-social-icons-img{
      width:20px !important;
     
    }
  }
    
  @media screen and (min-width: 320px) and (max-width:768px){
    .kode_footer_bg{
      background-size: 350px ;
      background-repeat: no-repeat;
      background-position: right 10px bottom;
      background-image: url('../assets/images/kode-footer.png');
      width:100%;
      height: auto;
    
    } 
  }
  @media screen and (min-width: 769px) and (max-width:991px){
    .kode_footer_bg{
      background-size: 550px ;
      background-position: right 10px bottom;
    
    } 
  }

  .form-footer{
    background: grey;
    border-radius: 25px;
    position: relative;
}

.h1-footer{
    color: white;
    padding: 15px;
    text-shadow: 2px 2px 2px black;
}

footer input{
    /* width: 100%;
    height: 100%; */
    display: block;
max-width: 100%;
    font-size: 20px;
    padding: 8px 40px 8px 20px;
    border: none;
    filter: drop-shadow(0px 5px 9px rgba(0, 0, 0, 0.05));
}

footer input:focus{
    outline: none;
}
.send-icon{
  position: absolute;
  top:20%;
  right:10%;
 
}
.footer-button{
  position: absolute;
  top: 0;
  right: 0;
  width: 50px;
  height: 100%;
  border-radius: 50%;
  cursor: pointer;
  border: none;
  background: none;
  font-size: 18px;
}



.placeholder-text{

  color: #8989A2;
  font-family: 'Poppins';
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
footer button:hover i{
    color: rgb(162, 163, 163);
}