/* .system_main_bg_position{
    background-color: rgb(151, 151, 250);
    height: auto;
    width:100%;
    padding-top: 3rem;
    padding-bottom: 3rem;
    position: relative;
    
}
.system_bg_main{
   
    width: 100%;
   
    padding-top: 3rem;
    padding-bottom: 3rem;
    background-color: rgb(250, 151, 176);
    height:700px;
  
}
.system_parent_div{
    position: absolute;
    top:-5%;
    left:0px;
}
.system_parent_div img{
    max-width: 100%;
    height: auto;
    display: block; 
    margin: 0 auto;
}
.system_parent_div_second {
    position: absolute;
  
   
    right: 0%;
    bottom: -10%;
}
.system_parent_div_second img{
    max-width: 100%;
    height: auto;
    display: block; 
    margin: 0 auto;
  
}
@media screen and (min-width: 300px) and (max-width: 767px) {
    .system_main_bg_position{
       
        position: static !important;
        
    }
    .system_parent_div{
position: static !important;
    }

} */
 /* background: radial-gradient(62.32% 62.32% at 48.69% 40.58%, #3EC8F7 7.38%, rgba(255, 255, 255, 0.00) 100%); */
  /* background: radial-gradient(62.32% 62.32% at 48.69% 40.58%, #3EC8F7 7.38%, rgba(255, 255, 255, 0.00) 100%); */


  /* Common styles for .system_main_bg_position */
.system_main_bg_position {
    background-color: rgb(151, 151, 250);
    padding-top: 3rem;
    padding-bottom: 3rem;
    width: 100%;
    max-width: 100%;
   
   
}

/* Styles for screens above 767px */
@media screen and (min-width: 768px) {
    .system_main_bg_position {
        height: auto;
        position: relative;
    }
    .system_bg_main {
        height: 700px;
    }
    .system_parent_div,
    .system_parent_div_second {
        position: absolute;
    }
    .system_parent_div {
        top: -5%;
        left: 0;
    }
    .system_parent_div_second {
        right: 0.1%;
        bottom: -10%;
      
    }
}

/* Styles for screens from 300px to 767px */
@media screen and (min-width: 300px) and (max-width: 767px) {
    .system_bg_main {
        height: auto;
    }
    .system_parent_div,
    .system_parent_div_second {
        position: static;
    }
    .system_main_bg_position {
        padding-top: 2rem;
        padding-bottom: 2rem;
    }
}

/* Common styles for images */
img {
    max-width: 100%;
    height: auto;
    display: block;
    /* margin: 0 auto; */
}
