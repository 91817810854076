header{
    height:15vh;
}
.hero_bg {
    background-image: url('../assets/impact/bg-hero.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: auto;
}

/* .hero-main {
    
    background: radial-gradient(62.32% 62.32% at 48.69% 40.58%, #3EC8F7 7.38%, rgba(255, 255, 255, 0.00) 100%);
    width: 100%;
    height: auto;
} */

.hero-img {
    width: 100%;
    height: auto;
}
.hero-img img {
    max-width: 100%;
    height: auto;
    display: block; /* To remove any extra space below the image */
}
.hero-text{
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.hero-text h2 {
    color: #0C173A;
    font-family: 'Work Sans';
    font-size: 65px;
    font-style: normal;
    font-weight: 700;

    text-transform: capitalize;
}

.hero-text h1 {
    -webkit-text-stroke: 1.9px #EB232c;
    color: transparent;
    font-family: 'Work Sans';
    font-size: 110px;
    font-style: normal;
    font-weight: 500;

    text-transform: capitalize;

    overflow: hidden;
}

.hero-text p {
    color: #7B99AA;
    font-family: 'Work Sans';
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 31px;
    /* 155% */
    letter-spacing: 0.2px;
    width:80%;
}

.bg {
    background: #325EE9;
    width: 100%;
    height: 240px;
    /* height: auto; */
}
.critical-bg{
    background-color: #fDEEEC;
    width:100%;
    height:auto;
    padding: 2rem;
}
.critrical_row{
    display: flex;
            flex-wrap: wrap;
}
.critical_colum{
    flex: 1;
    padding: 10px;
}
.critical_colum img {
    max-width: 100%;
    height: auto;
    display: block; /* To remove any extra space below the image */
}
.critical-text{
  justify-content: center;
  padding: 3rem 0rem 0rem 3rem;
}
.critical-text h1{
    color: #0C173A;
font-family: 'Work Sans';
font-size: 40px;
font-style: normal;
font-weight: 800;
line-height: 50px; /* 116.25% */
text-transform: capitalize;
}
.critical-text p{
    color: #494949;
font-family: 'Work Sans';
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: 25px; /* 138.889% */
letter-spacing: 0.2px;
padding-top: 1rem;
}
.health-bg{
  
    background-color: #EBEEFC; 
    width:100%;
    height:auto;
    padding: 7rem;
}
.health-bg img{
    max-width: 100%;
    height: auto;
    display: block; 
}
.health-text h1{
    color: #0C173A;
font-family: 'Work Sans';
font-size: 40px;
font-style: normal;
font-weight: 700;
line-height: 50px; /* 116.25% */
text-transform: capitalize;
padding-bottom: 1rem;
}
.health-text p{
    color: #494949;
font-family: 'Work Sans';
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: 25px; /* 138.889% */
letter-spacing: 0.2px;
}
.outcome-bg{
    background-color: #ECFAF0; 
    width:100%;
    height:auto;
    padding: 4rem; 
}
.outcome-bg img{
    max-width: 100%;
    height: auto;
    display: block; 
    margin: 0 auto;
}
.factors-bg{
    background-color: #f0fbfe; 
    width:100%;
    height:auto;
    padding: 5rem;
    
}
.factors-bg img{
    max-width: 100%;
    height: auto;
    display: block; 
    margin: 0 auto;
}
.factors_row{
    display: flex;
            flex-wrap: wrap;
}
.factor_colum{
    flex: 1;
            padding: 10px;
}
.factor-text h1{
    color: #0C173A;
font-family: 'Work Sans';
font-size: 40px;
font-style: normal;
font-weight: 700;
line-height: 46.5px; /* 116.25% */
text-transform: capitalize;
width:80%;
}
.factor-text p{

    color: #494949;
    font-family: 'Work Sans';
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px; /* 138.889% */
    letter-spacing: 0.2px;
    padding-top: 1rem;
}
.outcome-text{
    padding-top: 4rem;
}
.factor_colum img{
    padding-bottom: 2rem;
}
.outcome-text h1{
    color: #0C173A;
font-family: 'Work Sans';
font-size: 40px;
font-style: normal;
font-weight: 700;
line-height: 46.5px; /* 116.25% */
text-transform: capitalize;
width:70%;
}
.outcome-text p{
    color: #494949;
font-family: 'Work Sans';
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: 25px; /* 138.889% */
letter-spacing: 0.2px;
width:80%;
padding-top: 1rem;
}
.the_solution_text {
    text-align: center;
    justify-content: center;
}
.the_solution_text h4 {
    color: #7B99AA;
    font-family: 'Work Sans';
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px; /* 155% */
    /* text-transform: capitalize; */
}
.the_solution_text p{
    color: #ED553D;
    font-family: 'Work Sans';
    font-size: 35px;
    font-style: normal;
    font-weight: 700;
    line-height: 25px; /* 104.167% */
    letter-spacing: 0.2px;
}
.the_solution_text_span{
  
    border: 1px solid #ED553D;
    height: 1px;
   
    width: 126px;
    display: inline-block;
}

@media screen and (max-width: 768px) {
    .hero_mobile{
        flex-direction: column-reverse; 
    }

    .hero-img, .hero-text {
        width: 100%; 
    }
    /* .hero_bg {
        background-size: contain;
    
    } */
    .hero_mobile_sub {
        flex: 1; 
    }
    .hero-text h2 {
        font-size: 40px;
    }
    .hero-text h1 {
        font-size: 50px;
    }
    .critical_colum{
        flex: 1 100%;
    }
    .critical-text h1 {
        font-size: 30px;
       
    }
    .critical-text p {
      
        font-size: 16px;
        
    }
    .health-text h1{
        font-size: 30px;
    }
    .health-text p{
        font-size: 16px;
    }
    .factor-text h1{
        font-size: 30px;
    }
    .factor-text p{
        font-size: 16px !important;
    }
    .factor_colum{
        flex: 1 100%;
    }
    .outcome-text h1{
        font-size: 30px;
        width: 100%; 
    }
    .outcome-text p{
        font-size: 16px !important;
        width: 100%;
    }
    .health-bg {
       
        padding: 4rem;
    }

}

@media screen and (min-width: 769px) and (max-width: 1400px) {
    .critical-text h1 {
        font-size: 40px !important;
    }
    .health-text h1{
        font-size: 40px !important;
    }
    .factor-text h1{
        font-size: 40px !important;
    }
    
}

/* new added for impact card  */
.impact {
    /* width: 95%; */
    margin: auto;
}

.impa-card{
    /* width: 100%;    */
    width: 264px;   
    background-color: #325EE9;
    /* background: linear-gradient(135deg, rgba(255, 255, 255, 0.24) 0%, rgba(255, 255, 255, 0.06) 100%); */
    box-shadow: 0px 0px 11.695446968078613px -5.847723484039307px rgba(0, 0, 0, 0.50);
    backdrop-filter: blur(11.695446968078613px);
    text-align: center;
    border: 5px solid #fff;
}
.impa-card img{
    margin: auto;
    padding-top: 15%;
}
.impa-card h3{
    color: #FFF;
    font-family: Work Sans;
    font-size: 56px;
    font-style: normal;
    font-weight: 700;
    line-height: 31px; /* 55.357% */
    letter-spacing: 0.2px;
    margin-top: 20%;
}
.impa-card p{
    color: #FFF;
    text-align: center;
    font-family: Work Sans;
    font-size: 21px;
    font-style: normal;
    font-weight: 500;
    line-height: 31px; /* 147.619% */
    letter-spacing: 0.2px;
    margin-top: 16% !important;
    margin-bottom: 10% !important;
    width: 85%;
    margin: auto;

}
.imp-one,.imp-two,.imp-three{
    position: relative;
} 
/* .imp-one .impa-card{
    position: absolute;
} */

.imp-two .imp-2{
    position: absolute;
    top: -30%;
    left:25%;
}
.imp-three .impa-card{
    position: absolute;
    top: -15%;
    left: 45%;
}

@media screen and (min-width: 320px) and (max-width: 770px) {
    .bg {
        background: #325EE9;
        width: 100%;    
        height: auto;
    }
    .impact{
        display: flex;
        flex-direction: column;
        gap: 20%;
    }
    .imp-two .imp-2{ 
       margin-top: 5%;
        left:10%;
    }
    .imp-three .impa-card{
        position: absolute;
        top: 0px;
        left: 5%;
        display: none
    }

} 