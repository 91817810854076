.our_story_review_head h1 {
    color: #3856DF;
    text-align: center;
    font-family: 'Work Sans';
    font-size: 35px;
    font-style: normal;
    font-weight: 500;
    line-height: 47.5px;
    letter-spacing: 0.2px;
    width:90%;
}

.our_story_review_para p {
    color: #494949;
    font-family: 'Work Sans';
    font-size: 20px;
    font-style: normal;
    font-weight: 400 !important;
    line-height: 25px;
    /* 119.048% */
    letter-spacing: 0.2px;
    /* width: 82%; */
}

.man_img_div {
    height: auto;
    width: 100%;
}

.man_img_div img {
    display: block;
    width: 100%;
    height: auto;

}

.our_story_card {
    display: flex;
    height: auto;
    width: 80%;
    padding-bottom: 0px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  gap: 5px;
    border: 1px solid #EAECF0;
    background: #FFF;
  
  
}

.our_story_card h2 {
    color: #282828;

    font-family: 'Inter', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 1rem;
}

.our_story_card h4 {
    color: #5F6980;

    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    padding-left: 1rem;
    padding-right: 1rem;

}

.our_story_card p {
    color: #3E3E59;

    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    /* line-height: 22px; */
   
    padding-left: 1rem;
    padding-right: 1rem;
}
.icons_images{
    display: flex;
  
}
.icons_images img{
    display: block;
    padding: 1rem;

  
}

@media screen and (min-width: 300px) and (max-width: 768px) {
    .our_story_review_head h1 {
        color: #3856DF;
        text-align: center;
        font-family: 'Work Sans';
        font-size: 25px;
        font-style: normal;
        font-weight: 500;
        line-height: 37.5px;
        letter-spacing: 0.1px;
        width: 100%;
    }
    .our_values_card_text p {
        font-size: 18px;

    }
    .our_story_review_para p {
  
        font-size: 18px;
     text-align: center;
     padding-top: 1rem;
    }
    .our_story_card {
        display: flex;
        height: auto;
        width: 100%;
       
    }

    .our_story_card h2 {
        color: #282828;
        font-family: 'Inter', sans-serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 30px;
        padding-left: 1rem;
        padding-right: 1rem;
        padding-top: 1rem;
        text-align: center !important;
        margin: auto;
    }

    .our_story_card h4 {
        color: #5F6980;
        font-family: 'Inter', sans-serif;
        font-size: 18px;
      
        margin: auto;
    }
    .our_story_card p {
        color: #3E3E59;
        font-family: 'Inter', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        /* line-height: 22px; */
        padding-left: 1rem;
        padding-right: 1rem;
        margin: auto;
    }
    .icons_images {
        display: block;
        padding: 1rem;
        margin: auto !important;
        text-align: center;
        align-items: center;
    }
    .contact_us_text_head h1 {
        color: #325EE9;
        font-family: 'Poppins';
        font-size: 30px !important;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-align: center;
    }
}
