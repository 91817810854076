.core_values_bg{
    /* background-color: blue; */
    background: #325EE9;
    width:100%;
    height:auto;
}
.our_core_values_text{
    justify-content: center;
    text-align: center;
    align-items: center;
}
.our_core_values_text h1{
    color: #FFF;
font-family: 'Work Sans';
font-size: 43.314px;
font-style: normal;
font-weight: 700;
line-height: 23.977px; /* 55.357% */
letter-spacing: 0.155px;

}
.our_values_card{
    
border-radius: 7px;
background: linear-gradient(10deg, rgba(255, 255, 255, 0.24) 0%, rgba(255, 255, 255, 0.06) 100%);
box-shadow: 0px 0px 9.045938491821289px -4.5229692459106445px rgba(0, 0, 0, 0.50);
backdrop-filter: blur(9.045938491821289px);
height:300px;
align-items: flex-start;
padding: 3rem;
margin: 1rem;
}
.our_values_card_text{


   text-align: left;   
}
.our_values_card_text h4{
    color: #FFF;
font-family: 'Work Sans';
font-size: 30px;
font-style: normal;
font-weight: 700;
line-height: 23.977px; 
/* letter-spacing: 0.155px; */
padding-bottom: 1rem;
}
.our_values_card_text p{
    color: #FFF;
font-family: 'Work Sans';
font-size: 16.243px;
font-style: normal;
font-weight: 500;
line-height: 23.977px; /* 147.619% */
letter-spacing: 0.155px;
}
.our_values_card_text img{
    max-width: 100%;
    height: auto;
    display: block;
    margin: 0;
    padding-bottom: 1rem;
}

@media screen and (min-width: 320px) and (max-width: 768px) {
    .our_values_card{
        /* margin-top: 5% !important;
        margin: 0px; */
        width: 90%;
    }
    .our_values_card_text h4 {
      
        font-size: 25px;
        padding-bottom: 1rem;
    }
    .our_core_values_text h1 {
        font-size: 32px;
   
    }
}