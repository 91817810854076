*{
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}


body{
  background: #FFF;
  width:100%;
  height:auto;
  /* background: radial-gradient(62.32% 62.32% at 48.69% 40.58%, #3EC8F7 7.38%, rgba(255, 255, 255, 0.00) 100%); */
}