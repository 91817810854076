@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');

* {
    padding: 0px;
    margin: 0px;
    box-sizing: border-box;
    font-family: 'Poppins'
}
/* main_section --start */
/* CSS for the fixed navbar */
.navbar.fixed {
    position: fixed !important;
    top: 0;
    left: 0;
    right: 0;
    background-color: #ffffff; 
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); 
    z-index: 1000; 
}
.navbar.scrolled {
    background-color: #333333; 
    transition: background-color 0.3s ease; 
}

.main_section_view {
    width: 100%;
    height: auto;
    background-image: url('../assets/impact/bg-hero.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
   
    /* background-image: linear-gradient(#3EC8F7, #fff); */
}
.Beste_mergency_transitcare_para-2{
    color: #7B99AA;
font-family: 'Work Sans';
font-size: 18px;
font-style: normal;
font-weight: 600;
line-height: 25px;
letter-spacing: 0.2px;
}
.Beste_mergency_transitcare_para{
    font-weight: 500;
    font-size: 20px;
    line-height: 31px;
    letter-spacing: 0.2px;
    color: #7B99AA;
    padding-top: 1rem;
    font-family: 'Work Sans';
}
.main_banner_head{
   color: #ED553D !important; 
}
.main_section_view_layout{
    width: 90%;
    height: 850px;
    margin: auto;
}
.watch_our_vedio_rightside_view{
    padding-left: 6rem;
}
.watch_our_vedio_rightside_view_line{
    border: 1px solid #ed553d;
    display: inline-block;
    /* height: 1px; */
    width: 126px; 
}
.watch_our_vedio_rightside_view p{
    color: #7B99AA;
font-family: 'Work Sans';
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: 31px; /* 155% */
letter-spacing: 0.2px;
}
/* navbar --start */
.navbar_view{
    /* border:1px solid black; */
}
.navbar-expand-lg {
    padding: inherit !important;
    height: 100px;
}
.navbar_logo {
    width: 429px;
    height: 148px;
    /* height: 94px; */
    /* background-image: url('../assets/images/Ellipse.png'); */
    background-size: cover;
    background-repeat: no-repeat;
    /* border-radius: 50%; */
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-image: linear-gradient(#D1F2FD,#3EC8F7);
    box-shadow: 10px 8px 128px 5px #3EC8F7; */
    /* transform: translate3d(0,0,-1px);
    background: conic-gradient(from 90deg at 40% -25%, #ffd700, #f79d03, #ee6907, #e6390a, #de0d0d, #d61039, #cf1261, #c71585, #cf1261, #d61039, #de0d0d, #ee6907, #f79d03, #ffd700, #ffd700, #ffd700);
    filter: blur(10px); */
}
.navbar-brand{
width: 82px !important;
height: 62px !important;
}
.navbar-brand img{
    width: 100%;
    height: auto;
    object-fit: cover;
}
.nav-item{
    font-size: 18px !important;
    margin: 0px 10px;
    position: relative;
    top:3px;
    color: #7B99AA;
    font-weight: 600;
}
.nav-link .active{
    color: #000000;
}
.Login_item a{
color: #ED553D !important;
}
/* .nav-link .active::after{
    content: '';
    width: 100px;
    height: 3px;
    background-color: red;
} */
.navbar_items_view{
    /* border: 1px solid #212529; */
    width: 100%;
    display: flex;
    justify-content: center;
}
.request_demo{
    width: 189px;
    height:52px;
    padding: 15px 25px 15px 25px;
    background-color:#ED553D;
    color:#fff;
    font-size:14px;
    border-radius: 5px;
    border:1px solid transparent;
    letter-spacing: 0.2px;
    line-height: 22px;
    font-weight: 700;
    display: flex;
    align-items: center;
    text-decoration: none;
}
.request_demo img{
    padding-left: 12px;
}
.navbar_item_flex_view{
    display: flex;
}
.navbar_item_flex_view a{
    text-decoration: none;

}
@media only screen and (max-width: 1200px) {
    .navbar_logo {
        width: 100px;
        height: 100px;
        background-image: none;
    }
  }
  @media only screen and (max-width: 990px) {
    .navbar-collapse{
        background-color: #fff;
        z-index:999;
        border-radius: 4px;
        padding: 10px;
    }
  }
  @media only screen and (max-width: 500px) {
    .navbar_logo {
        width: 100px;
        height: 100px;
        background-image: none;
    }
    .navbar_item_flex_view{
        display: block;
    }
    .navbar-collapse{
        background-color: #fff;
        z-index:999;
        border-radius: 4px;
        padding: 10px;
    }
  }
/* navbar --end */

/* banner --start */
.banner_view{
    margin: 90px 0px;
}
.banner_layout{
    width: 100%;
    display: flex;
    justify-content: space-between;
    /* overflow: hidden; */
}
.VectorImg_view{
    width: 100%;
    position: relative;
    margin-bottom: 27px;
}
.VectorImg_view img{
    max-width: 100%;
    height: 55px;
}
.VectorImg_view p{
    position: absolute;
    top: 14px;
    left: 34px;
    font-weight: 700 !important;
    font-size: 18px !important;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: #ED553D !important;

}
.banner_leftside_view{
    /* border:1px solid red; */
    /* width: 40% !important; */
}
.banner_leftside_view a{
    text-decoration: none !important;
}
.banner_leftside_view h1{
    font-weight: 700;
    font-size:50px;
    line-height: 60px;
    padding-bottom: 27px;
}
.banner_leftside_view p{
    font-weight:500;
    font-size: 20px;
    line-height: 31px;
    letter-spacing: 0.2px;
    color: #7B99AA;
    padding-bottom: 16px;
}
.banner_rightside_view{
    position: relative; 
    height: 554px; 
    margin-top: -5%;
}
/* .banner_rightside_view .banner_rightside_img1{
    position: absolute;
    top: -93px;
    right: -89px;
    z-index: 9;
} */
.banner_rightside_view .banner_rightside_img1{
   width: 100%;
   height: auto;
   position: relative;

   /* top: -85px; */
}
.banner_rightside_view .BloodpressureImg{
    position: absolute;
    left: -23%;
    top: 20%;
    z-index: 9;
}
.banner_rightside_view .Frame_img{
    position: absolute;
    left: -15%;
    bottom: -40%;
    z-index: 9;
}
.banner_rightside_view .Untitled_img{
    position: absolute;
    right: 20%;
    bottom: -19%;
    z-index: 9;
}
.banner_rightside_view .Vectors1_img{
    position: absolute;
    top: 145px;
    left:136px;
    /* left: 100px; */
}
@media only screen and (max-width:1920px) {
    .banner_leftside_view h1{
        font-size:46px;
        line-height: 120%;
        padding-bottom: 27px;
    }

    .banner_leftside_view p{
        font-weight:500;
        font-size: 20px;
        line-height: 31px;
        letter-spacing: 0.2px;
        color: #7B99AA;
        padding-bottom: 16px;
    }
}
@media only screen and (max-width:990px) {
    .banner_layout{
        width: 100%;
        display: block;
        justify-content: space-between;
    }
    .banner_leftside_view{
        width: 100% !important;
        margin-bottom: 10px;
    }
    .banner_rightside_view{
        /* right: 98px; */
        height: auto;
        width:100% !important;
    }
    .banner_leftside_view h1{
        font-size:50px;
        line-height: 60px;
        padding-bottom: 27px;
    }
    .banner_leftside_view p{
        font-weight:500;
        font-size: 18px;
        line-height: 31px;
        letter-spacing: 0.2px;
        color: #7B99AA;
        padding-bottom: 16px;
    }
}
@media only screen and (max-width:500px) {
    .banner_layout{
        width: 100%;
        display: block;
        justify-content: space-between;
    }
    .banner_leftside_view{
        width: 100% !important;
        margin-bottom: 10px;
    }
    .banner_rightside_view{
        /* right: 98px; */
        height: auto;
        width:100% !important;
    }
    .banner_leftside_view h1{
        font-size:30px;
        line-height: 40px;
        padding-bottom: 21px;
    }
    .banner_leftside_view p{
        font-weight:500;
        font-size: 15px;
        line-height: 31px;
        letter-spacing: 0.2px;
        color: #7B99AA;
        padding-bottom: 16px;
    }
    .VectorImg_view {
        width: 100%;
        position: relative;
        margin-bottom: 22px;
    }
}

/* banner --end */


.watch_our_vedio_here_view{
    width: 90%;
    height: auto;
    margin: auto;
    /* width: 100%;
    height: auto; */
    /* background-color: red; */
} 
.watch_our_vedio_leftside_view{ 
    position: relative;
}
.watch_our_vedio_leftside_emptydiv{
    Width:90%;
    Height:367px;
    border-radius:25px;
    background: linear-gradient(359.26deg, #DEF0FC -24.48%, #EFF2FD 95.47%, rgba(222, 240, 252, 0) 148.44%);
}

.watch_our_vedio_img{ 
    max-width: 100%;
    Height: 348px;
    position: absolute; 
    left: 60px;
    top: -38px;
    border-radius: 25px !important;

}
/* .watch_our_vedio_rightside_view{
    width: 50%; 
} */
.watch_our_vedio_rightside_view h2{
    font-weight: 700;
   font-size: 35px;
    line-height: 46px;
    color: #0C173A;
    /* margin-top: 70px; */

}
.watch_our_vedio_rightside_view p{
   font-weight: 500;
    font-size: 20px;
    line-height: 31px;
    letter-spacing: 0.2px;
    color: #7B99AA;
    padding-top: 1rem;

}

@media only screen and (max-width:1484px) {
    .watch_our_vedio_leftside_view{ 
        position: relative; 
    } 
}

/* Beste_mergency_transitcare_assured --start  */
.Beste_mergency_transitcare_assured_view{
    width: 90%;
height: auto;
margin: auto;
} 
.Beste_mergency_transitcare_assured_rightside{
    padding-top: 19%;

}
.Beste_mergency_transitcare_assured_rightside h2{
   font-weight:700;
   font-size: 35px;
   line-height:46.5px;
   color: #0C173A;
}
.Best_emergency_transit_care_assured_line{
    border: 1px solid #ed553d;
    display: inline-block;
    /* height: 1px; */
    width: 126px;
}
.Beste_mergency_transitcare_assured_rightside p:nth-child(5){
    font-weight: 500;
    font-size: 16px;
    line-height: 25px;
    letter-spacing: 0.2px;
    color: #7B99AA;
    margin-top: 30px;
}
.Beste_mergency_transitcare_assured_rightside p:nth-child(6){
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    letter-spacing: 0.2px;
    color: #7B99AA;

   
}

/* main_section --end */


/* new added */
.best-emergency-img{
    width: 100%;
}

@media screen and (min-width:320px) and (max-width:768px) {
    .VectorImg_view img{
        width: 100%;
    }
    .banner_leftside_view p {
        font-size: 12px !important;
        left: 3%;
    }
    .banner_rightside_view .BloodpressureImg{
        width: 50%;
        left: 0px;
        top: 2%;
    }
    .banner_rightside_view .Frame_img{
        width: 60%;
        left: -9%;
        bottom: -70%;
    }
    .banner_rightside_view .Untitled_img{
        width: 40%;
        right: 0px;
        bottom: -35%;

    }
    .watch_our_vedio_leftside_emptydiv{
        height: 220px;
        margin-left: -9%;
    }
    .watch_our_vedio_img{ 
        height: auto;
        left: 0px;
        top: 2%; 
    }
    .watch_our_vedio_rightside_view h2{
        font-size: 25px;
    }
    .watch_our_vedio_rightside_view p{
        font-size: 15px;
    }
    .Beste_mergency_transitcare_assured_rightside h2{
        font-size: 25px;

    }
    .p-image-1,.p-image-2,.p-image-3,.p-image-4,.plus_img-1,.plus_img-2{
        display: none;
    }
}

@media screen and (min-width:320px) and (max-width:700px) {
    .watch_our_vedio_here_layout{
        display: flex;
        flex-direction: column-reverse;
    }
    .Beste_mergency_transitcare_assured_layout{
        display: flex;
        flex-direction: column-reverse;
    }
}



/* ==================================================*************************==================================== */

.how-it-work{
    height: auto;
    width: 80%;
    /* background-color: aqua; */
    margin-top: 10%;
    margin-bottom: 5%;  
}
.how-head{
    text-align: center;
}
.how-head h2{
    color: #ED553D;
    font-family: 'Work Sans';
    font-size: 35px;
    font-style: normal;
    font-weight: 700;
    line-height: 25px; /* 104.167% */
    letter-spacing: 0.2px;
    
}
.how-head h3{
    color: #0C173A;
    font-family: 'Work Sans';
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    padding-top: 1rem;
  

}
.how-div{
    padding-top: 2%;
}
.how-content{
    display: flex;
    gap: 10%;
}
.how-content h3{
    color: #000;
    font-family: 'Work Sans';
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 46.5px; /* 221.429% */
}
.how-content-p p{
    color: #7B99AA;
    font-family: 'Work Sans';
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 33px; /* 206.25% */
}
.hr-line{
    margin: auto;
    background: rgba(0, 0, 0, 0.15);
    height: 1px;
    width: 70%;
}
.image-one-div{
    padding-top: 18%;
    z-index: 20;
}
.image-one{
    width: 90%;
    height: auto; 
    margin-left: -7%; 
}
.image-con{
    position: relative;
}
.image-two{
    position: absolute;  
    top: -20%;
    left: 15%; 
    z-index: 22;
}
.image-three{
    position: absolute;
    right: 20%;
    top: -26%;

}
.image-four{
    position: absolute;
    right: 0px;
    top: 30%;
}
.curve-1{
    position: absolute;
    top: 11%;
    left: 5%;
    z-index: 11;
}
.curve-2{
    position: absolute;
    top: -40%;
    left: 30%;
    width: 40%;
}
.curve-3{
    position: absolute;
    right: 6%;
    top: -10%;
}



/* ====================================================================================== */
.div-space{
    padding-top: 5%;
    padding-bottom: 5%;
}
.system{
    position: relative;
    /* background-color: #ADD9F8;  */
    /* opacity: 0.4000000059604645; */
    /* border: 1px solid red;
    background-image: url(../assets/images/rectangle.png);
    background-repeat: no-repeat;
    background-size: cover; */
}

.system-img{
    /* position: absolute; */
    background-size: cover;
    background-image: url(../assets/images/rectangle.png);
    height: 82%;
    border: 0.1px solid #ADD9F8; 

}

.mob-div{
    margin-top:-10%;
}
.mob-div-1{
    padding-top: 8%;
}


.system-enable{
    margin-left: -10%;
    width: 90%; 
}
.system-enable h2{
    color: #000;
    font-family: 'Work Sans';
    font-size: 35px;
    font-style: normal;
    font-weight: 700;
    line-height: 50.5px; /* 84.286% */
}
.system-enable h2 span{
    color: #ED553D;
    font-family:'Work Sans';
    font-size: 35px;
    font-style: normal;
    font-weight: 700;
    line-height: 29.5px; /* 84.286% */
}

.system-enable p{
    color: #7B99AA;
    font-family: 'Work Sans';
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 33px; /* 206.25% */
    width: 90%;
}
.mobile-system{
    width: 90%;
    height: auto;
}
.laptop-system{
    width: 100%;
    height: auto;
    margin-top: -20%;
}
.system-card{
    /* height: auto; */
    height: 105px;
    width: 90%;
    border-radius: 7px;
    background: #FFF;
    display: flex;
    gap: 9%;
    align-items: center;
    padding: 6%;
}
.system-card h5{
    color: #363636;
    font-family: 'Work Sans';
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 29.5px; /* 147.5% */
}
.system-ca{
    display: flex; 
}
.sys{
    position: relative;
}
.system-d{
    margin-top: 14%;
    margin-left: 10%;
}
 


  /* mobile-div */
  .how-work{

  }
  .how-work h4{
    color: #ED553D;
    font-family: 'Work Sans';
    font-size: 21px;
    font-style: normal;
    font-weight: 700;
    line-height: 25px; /* 119.048% */
    letter-spacing: 0.2px;
    
  }
  .how-work h2{
    color: #0C173A;
    font-family: 'Work Sans';
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: 46.5px; /* 186% */

  }
  .how-work .p-div{
    background: #ED553D;
    height: 2px;
    width: 30%;

  }
  .mobile-div{
    height: auto;
    position: relative;
  }
  .section-1{
    position: relative;
    height: auto;
  }
  .image-1{
    position: absolute;
  }
 .section-1-div img{
    margin-left: inherit;
 }
 .section-1-div h3{
    color: #000;
    font-family: Work Sans;
    font-size: 21px;
    font-style: normal;
    font-weight: 600; 
 }
 .section-1-div p{
    color: #7B99AA;
    font-family: 'Work Sans';
    font-size: 14px;
    font-style: normal;
    font-weight: 400; 
 }
  .section-2{
    position: relative;
    height: auto;
  }
  .image-2{
    position: absolute; 
    height: 190px;
    width: 38%;
  }
  .section-2-div{
    text-align: right;
  }
 .section-2-div img{
    margin-right: inherit;
 }
 .section-2-div h3{
    color: #000;
    font-family: 'Work Sans';
    font-size: 21px;
    font-style: normal;
    font-weight: 600; 
 }
 .section-2-div p{
    color: #7B99AA;
    font-family: Work Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400; 
 }

 @media screen and (min-width:768px) and (max-width:2560px) {
    .mobile-divv{
        display: none;
    }
 }
 @media screen and (min-width:355px) and (max-width:480px) {
    .m-curve-1{
        margin-top: 4% !important;
    }
    .m-curve-2{
        margin-top: -3% !important;
    }
    .m-curve-3{
        margin-top: 10% !important;
    }

 
}

@media screen and (min-width:320px) and (max-width:767px) {
    .system-enable{
        margin-left: 0px;
        width: 100%;
    }
    .laptop-div{
        display: flex;
        flex-direction: column-reverse;
    }
    .laptop-system{ 
        margin-top: 0;
    }
    .curve-1,.curve-2,.curve-3{
        display: none;
    }
  }

 @media screen and (min-width:320px) and (max-width:767px) {
    .desktop-div{
        display: none;
    }
    .image-1{
        width: 40%;
        margin-left: -8%;
        top: 13%;
    }
    .m-curve-1{
        /* max-width: 100%; */
        margin-top: -20%;
    }
    .m-curve-2{
        margin-top: -25%;
        max-width: 100%;
        margin-bottom: -12%;
    }
    .m-curve-3{
        margin-top: -10%;
        max-width: 100%;
    } 
    .Beste_mergency_transitcare_assured_rightside{
        margin-top: 10%;
    }
}
@media screen and (min-width:320px) and (max-width:768px) {
    .image-two{
      width: 80%;
    }
    .how-it-work {
      width: 95%;
    }
    .curve-1,.curve-2,.curve-3 {
      display: flex;
      max-width: 100%; 
      left: 0px;
    }
    .system-card{
        width: 100%;
    }
    .system-card img{
        width: 30px;
        height: 30px;
    }
    .system-card h5{
        font-size: 16px;
        font-weight: 400;
        line-height: 140%;
    }
    .system-d{
        margin-left: 0px;
    }
    .patient_card_sub span {
        font-size: 15px;
    
    }
  }


@media screen and (min-width:700px) and (max-width:768px) {
    .watch_our_vedio_here_view{ 
        margin-top: 20%;
    }
    .watch_our_vedio_leftside_view {
        margin-top: 20%;
    }
    .banner_rightside_view .Frame_img{
        width: 30%;
        left: 1%;
        bottom:-30%;
    }
    .banner_rightside_view .Untitled_img {
        width: 25%;
        right: 20%;
        bottom: -15%;
    }
    .image-one{
        width: 100%;
    }
    .image-two {
        width:30% ;
        top: -15%;
    }
    .image-three{
        width: 30%;
        top: -20%;
        right: 10%;
    }
    .image-four{
        width: 20%;
    }
    .how-it-work{
        width: 75%;
    }
    .laptop-system{
        margin-top: 0px;
    }
    .how-head h3 {
        font-size: 20px;
        line-height: 120%;
    }
    .curve-1{
        width: 26%;
        top: 3%;
        left: 4%;
    }
    .curve-2{
        width: 40%;
        left: 30%;
        top: -29%;
    }
    .curve-3{
        width: 27%;
        right: 0px;
        margin-left: 70%;
        top: 0px;
    }
    .how-content {
        margin-top: 10%
    }
    .how-content h3{
        font-size: 18px;
        line-height: 120%; 
    }
    .how-content-p p{
        font-size: 13px;
    }
    .how-content img{
        width: 40px;
        height: 40px;
    }
    .desktop-div{
        margin-top: 10%;
    }
}
@media screen and (min-width:599px) and (max-width:760px) {
    .image-1{
        width: 35%;
    }
    .m-curve-1{
        width: 60%;
        margin-top: 10%;
        margin-left: 15%;
    }
    .m-curve-2{ 
        width: 60%;
        margin-top: -6%;
        margin-bottom: -5%;
        margin-left: 15%;
    }
    .m-curve-3{ 
        width: 60%;
        margin-top: 16%;
        margin-left: 13%;
    } 
    .section-1-div h3, .section-2-div h3{
        font-size: 30px;
    }
    .section-1-div p,.section-2-div p{
        font-size: 19px;
    }
}

@media screen and (min-width:991px) and (max-width:1280px) {
    .how-it-work{
        width: 75%;
    }
    .how-content h3{
        line-height: 120%;
        margin-top: 5%;
    }
    .image-one{
        width: 100%;
    }
    .image-two{
        width: 28%;
    }
    .image-three{
        width: 30%;
        right: 10%;
    }
    .image-four{
        
    }
    .curve-1{
        width: 28%;
        top: 3%; 
    }
    .curve-2 {
       width: 40%; 
       left: 27%; 
       top: -35%;
    }
    .curve-3{
        width: 28%;
        top: 0px;
        right: 3%;
    }
}

@media screen and (min-width:320px) and (max-width:990px) {
    .best_img,.best_img-1{
        background-image: none !important;
    }
    .img-vector{
        position: relative;
        top: 18%;
        right: 0px !important;
    }
}

.best_img{
    /* position: relative; */
    background-image: url('../assets/images/plus-1.svg'); 
    background-position: top right;
    background-size: 300px;
    width: 100%;
    height: auto;
    background-repeat: no-repeat;
    overflow: hidden;
}

.best_img-1{ 
    background-image: url('../assets/images/plus-1.svg'); 
    background-position: bottom left;
    background-size: 300px;
    width: 100%;
    height: auto;
    background-repeat: no-repeat;
    overflow: hidden;
}

.plus_img-1{
    position: absolute;
    right: 0px; 
}
.plus_img-2{
    position: absolute;
    bottom: 0px;
    left: -10%;
}

.img-vector{
    position: relative;
    top: 1%;
    right: -11%;
    width:100%;
   transform: skew(1);
}

.youtube-wrapper iframe {
    
    border-radius:25px;
    overflow: hidden; /* This ensures the video corners are also rounded */
  }

#navbarSupportedContent{
    z-index: 10;
} 
@media screen and (min-width:320px) and (max-width:768px) {
    .youtube{
        max-width: 300px !important;
    }

    .youtube-wrapper iframe {
        max-width: 100% !important;
        border-radius:25px;
        overflow: hidden; /* This ensures the video corners are also rounded */
      }


    
}
@media screen and (min-width:320px) and (max-width:1350px) {

    .system-img {
    
        height: 102%;
   
    }

}

@media screen and (min-width:1800px) and (max-width:2600px) {

    .mob-div-1{
        padding-top: 16%;
    } 

}


.fixed-navbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #fff; /* Adjust as needed */
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); /* Optional: Add shadow for aesthetics */
    z-index: 1000; /* Ensure the header is above other content */
    transition: background-color 0.3s ease; /* Optional: Add a smooth background color transition */
  }
  .our_partners h2{
    align-items: center;
    color: #160637;
    font-family: 'Work Sans';
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 25px;
    letter-spacing: 0.2px;
    justify-content: center;
    text-align: center;
  }
  .our_partners-span{
    border: 1px solid #ED553D;
    height: 1px;
    width: 120px;
    /* display: inline-block; */
    /* justify-content: center;
    align-items: center;
    text-align: center; */
    margin: auto;
  }
  .watch_our-video_small{

  color: #7B99AA;
    font-family: 'Work Sans' !important;
    font-size: 18px !important;
    font-style: normal;
    font-weight: 600 !important;
    line-height: 25px;
    letter-spacing: 0.2px;
  }
  .pt-img img{
    width:200px;
  }
  @media (max-width: 768px) {
    .youtube-wrapper {
      width: 100% !important; /* Change the width to 100% for mobile devices */
    }
    .watch_our_vedio_rightside_view {
        padding-left: 0rem !important; 
    }
    .system-enable h2 {
        font-size: 25px !important;
    }
    .system-enable h2 span {
        font-size: 25px !important;
      
    }
    .system-enable p {
      
        font-size: 14px !important;
       
  }
}