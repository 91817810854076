.man_div_bg {
    /* background: linear-gradient(182deg, #3EC8F7 0%, #D1F2FD 100%);
    background-position: center center; */
    background-image: url('../assets//home/man-bg.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
    

}
.man_div {
    display: flex;
}

.man_div_img {
    height: 80px
}
.stars_div {
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
    padding: 1rem;
}
.stars_div img{
    width:100px;
}
.career-component3 {
    /* background: #f5f5f5;
    border-radius: 15px;
    border: 9px solid #fffbf7; */
    padding: 30px 30px;
    border-radius: 10px;
    border: 1px solid #D0E3FB;
    background: #FFF;
    margin: 0.7rem;
}

.stars_div h4 {
    color: #000;
    text-align: center;
    font-family: 'Work Sans';
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    /* line-height: 47.5px; */
    /* 237.5% */
    letter-spacing: 0.2px;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
}

.stars_div p {
    color: #C0C0C0;
    text-align: center;
    font-family: 'Work Sans';
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    /* line-height: 14px; */
    /* 100% */
    letter-spacing: 0.2px;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
}
.text_stars p{
    color: #7B99AA;
font-family: 'Work Sans';
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 33px; /* 206.25% */
}
.cards_text_head{
    text-align: center;
    justify-content: center;
    align-items: center;
    margin: auto;
}
.cards_text_head h1{
    color: #3856DF;
text-align: center;
font-family: 'Work Sans';
font-size: 35px;
font-style: normal;
font-weight: 500;
line-height: 47.5px; /* 135.714% */
letter-spacing: 0.2px;

}


@media(max-width:992px) {
    .looking-for-para {
        height: auto;
        margin-bottom: 30px;
    }
}

@media screen and (min-width: 300px) and (max-width: 768px) {
    .career-component3 {
        margin: 0rem;
    }
    .cards_text_head h1 {
    
        font-size: 25px !important;
}

.stars_div h4 {
    font-size: 18px !important;
}
}